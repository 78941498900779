<template>
  <footer :class="$style.root">
    <ul :class="$style.list">
      <li :class="$style.item">
        <I18nLink
          :to="{name: 'index'}"
          :class="$style.link"
        >
          {{ $t('siteTitle') }}
        </I18nLink>
      </li>
      <li
        v-for="item in items"
        :key="item.id"
        :class="$style.item"
      >
        <NavigationSiteHeaderItemComponent
          :item="item"
          :class="$style.link"
        />
      </li>
    </ul>
  </footer>
</template>

<script setup>
const i18nActiveIso = computed(() => baseI18nGetActiveIso())

const { data, error } = await useAsyncData(() =>
  $fetch('/api/strapi-rest', { query: { path: queryMenusFooter(i18nActiveIso.value) } }),
  { watch: [i18nActiveIso] }
)

const page = baseStrapiGetAttributes(data)
const items = page?.items?.data
</script>

<style module lang="scss">
.root {
  composes: font-size-default from global;

  padding: var(--padding--container);
  margin-top: calc(var(--unit--vertical) * 2);
}

.list {
  composes: reset-list from global;

  columns: 2;
  gap: var(--unit--default);

  @media (max-width: $breakpoint-m) {
    columns: 1;
  }
}

.item {
  break-inside: avoid;
}

.link {
  composes: reset-link link from global;
}
</style>
