<template>
  <section
    :class="$style.root"
    v-show="consentSetting !== false && consentSetting !== true"
  >
    <div :class="$style.content">
      <h1
        v-if="attributes(data)?.label"
        :class="$style.label"
        v-html=attributes(data)?.label
      />

      <div
        v-if="richText"
        :class="$style.richText"
      >
        <CoreTextComponent
          v-if="richText"
          :string="richText"
          :key="richText"
        />
        {{ truncateRichText && textTruncated ? ' ' : null }}
        <button
          :class="$style.button"
          v-if="truncateRichText && textTruncated"
          @click="textTruncated = false"
        >
          {{ $t("shared.readMore") }}
        </button>
      </div>

      <div :class="$style.buttons">
        <button
          :class="$style.button"
          @click="grantGtagConsent()"
        >
          {{ $t("cookies.accept") }}
        </button>

        <button
          :class="$style.button"
          @click="revokeGtagConsent()"
        >
          {{ $t("cookies.revoke") }}
        </button>

        <I18nLink
          v-if="attributes(data)?.pageEntry?.data?.attributes"
          :to="{
            name: 'slug',
            params: { slug: attributes(data)?.pageEntry?.data?.attributes?.slug }
          }"
          :class="$style.link"
        >
          {{ attributes(data)?.pageEntry?.data?.attributes?.title }}
        </I18nLink>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
  const { gtag, grantConsent, revokeConsent } = useGtag()
  const consentSetting = useCookie('gtagConsent', { maxAge: 60 * 60 * 24 * 7 })

  const grantGtagConsent = () => {
    grantConsent()
    consentSetting.value = true
  }

  const revokeGtagConsent = () => {
    revokeConsent()
    consentSetting.value = false
  }

  const i18nActiveIso = computed(() => baseI18nGetActiveIso())

  const { data, error } = await useAsyncData(() =>
    $fetch('/api/strapi-rest', { query: { path: queryCookieConsent(i18nActiveIso.value) } }),
    { watch: [i18nActiveIso] }
  )

  const attributes = baseStrapiGetAttributes

  const truncateRichText = attributes(data.value)?.richText?.split(' ')?.length > 30
  const textTruncated = ref(true)
  const richText = computed(() => truncateRichText && textTruncated.value ? baseTruncateString(attributes(data.value)?.richText, 20) : attributes(data.value)?.richText)

  onMounted(() => {
    if(consentSetting.value === true) {
      grantConsent()
    }
  })
</script>

<style module>
.root {
  position: fixed;
  z-index: var(--z-index--cookie-consent);
  bottom: 0;
  right: 0;
  left: 0;
}

.content {
  padding: var(--unit--default);
  background-color: var(--color--brown);
  color: var(--color--white);
}

.label {
  composes: reset-heading from global;
  text-transform: uppercase;
  margin-bottom: var(--unit--default)
}

.link {
  composes: reset-link link from global;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: var(--unit--default);

  margin-top: var(--unit--default);
}

.button {
  composes: reset-button from global;
}

.button:hover {
  color: var(--color--magenta);
}

.richText * {
  display: inline;
}
</style>
