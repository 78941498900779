export const queryMenusItems = {
  items: {
    populate: {
      bulletin_board_entry: {
        fields: ['title', 'slug', 'locale'],
        populate: {
          localizations: {
            fields: ['title', 'slug', 'locale'],
          }
        }
      },
      bulletin_board_page: {
        fields: ['title', 'locale'],
        populate: {
          localizations: {
            fields: ['title', 'locale'],
          }
        }
      },
      calendar_entry: {
        fields: ['title', 'slug', 'locale'],
        populate: {
          localizations: {
            fields: ['title', 'slug', 'locale'],
          }
        }
      },
      calendar_page: {
        fields: ['title', 'locale'],
        populate: {
          localizations: {
            fields: ['title', 'locale'],
          }
        }
      },
      community_page: {
        fields: ['title', 'locale'],
        populate: {
          localizations: {
            fields: ['title', 'locale'],
          }
        }
      },
      home_page: {
        fields: ['title', 'locale'],
        populate: {
          localizations: {
            fields: ['title', 'locale'],
          }
        }
      },
      organisation_entry: {
        fields: ['title', 'slug', 'locale'],
        populate: {
          localizations: {
            fields: ['title', 'slug', 'locale'],
          }
        }
      },
      organisation_page: {
        fields: ['title', 'locale'],
        populate: {
          localizations: {
            fields: ['title', 'locale'],
          }
        }
      },
      page_entry: {
        fields: ['title', 'slug', 'locale'],
        populate: {
          localizations: {
            fields: ['title', 'slug', 'locale'],
          }
        }
      },
      project_entry: {
        fields: ['title', 'slug', 'locale'],
        populate: {
          localizations: {
            fields: ['title', 'slug', 'locale'],
          }
        }
      },
      project_page: {
        fields: ['title', 'locale'],
        populate: {
          localizations: {
            fields: ['title', 'locale'],
          }
        }
      }
    }
  }
}
