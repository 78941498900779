import qs from 'qs'

const fields = [
  'label',
  'richText'
]

export const queryCookieConsent = (locale: QueryLocaleString) => {
  const query = qs.stringify(
    {
      locale: locale || 'en-US',
      fields: fields,
      populate: {
        localizations: {
          fields: fields
        },
        pageEntry: {
          fields: [
            'title',
            'slug'
          ]
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `cookie-consent?${query}`
}
