<template>
  <i18nLink
    v-if="routeObject && linkItem?.title"
    :to="routeObject"
    :data-current-route="active ? '' : null"
  >
    {{ linkItem?.title }}
  </i18nLink>
  <a v-else-if="item?.attributes?.email" :href="`mailto:${item?.attributes?.email}`">
    {{ item?.attributes?.email }}
  </a>
  <a v-else-if="item?.attributes?.url?.length" :href="item?.attributes?.url" target="_blank">
    {{ item?.attributes?.title }}
  </a>
</template>

<script setup>
const nuxtApp = useNuxtApp()

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

const linkItem = computed(() => getLinkItem(props.item))
const name = strapiGetRouteReference(props?.item?.uid)?.name
const routeName = computed(() => nuxtApp.$getRouteBaseName())
const slug = computed(() => route?.params?.slug)

const routeObject = baseRouteGetObject(
  strapiGetRouteReference(linkItem?.value?.uid)?.name,
  linkItem?.value?.slug
)

const active = computed(() => {
  const routesWithChildren = ['bulletin-board', 'calendar', 'community', 'organisation', 'projects']
  const routeWithChildren = routesWithChildren?.some(i => i?.startsWith(name))
  if (routeWithChildren) {
    const match = routesWithChildren?.find(i => i?.startsWith(name))
    return routeName.value?.startsWith(match)
  } else {
    if (props?.item?.slug) {
      if (props?.item?.slug === slug.value && name === routeName.value) {
        return true
      } else {
        return false
      }
    } else {
      return name === routeName.value
    }
  }
})
</script>
